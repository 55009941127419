import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import ImageLinkList from '../components/imageLinkList'
import { secondary } from '../constants'

const FlexRowCenteredWrap = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 20px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
`

const LinksWrapper = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom || 'initial'};
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 2em;
`

const TitleRow = styled.div`
  display: flex;
`
const Focused = styled.h2`
  color: ${secondary};
  font-style: italic;
  margin-left: 0.5em;
`

const ProjectsPage = ({ data, pageContext }) => {
  const [focusedUri, setFocusedUri] = useState(null)
  const { soloLabel, otherLabel, archivedLabel } = pageContext
  const combined = [
    ...data.solo.projects,
    ...data.other.projects,
    ...data.archived.projects,
  ]
  const focused = combined.find((project) => project.uri === focusedUri)
  return (
    <FlexRowCenteredWrap>
      <div>
        {!!data.solo.projects.length && (
          <>
            <TitleRow>
              <h2>{soloLabel}</h2>
              {!!focused && focused.type === 'solo' && (
                <Focused>{focused.title}</Focused>
              )}
            </TitleRow>
            <LinksWrapper>
              <ImageLinkList
                items={data.solo.projects}
                uriPrefix="de/projects"
                onMouseEnter={setFocusedUri}
                onMouseOut={() => setFocusedUri(null)}
              />
            </LinksWrapper>
          </>
        )}

        {!!data.other.projects.length && (
          <>
            <TitleRow>
              <h2>{otherLabel}</h2>
              {!!focused && focused.type === 'other' && (
                <Focused>{focused.title}</Focused>
              )}
            </TitleRow>
            <LinksWrapper>
              <ImageLinkList
                items={data.other.projects}
                uriPrefix="de/projects"
                onMouseEnter={setFocusedUri}
                onMouseOut={() => setFocusedUri(null)}
              />
            </LinksWrapper>
          </>
        )}

        {!!data.archived.projects.length && (
          <>
            <TitleRow>
              <h2>{archivedLabel}</h2>
              {!!focused && focused.type === 'archived' && (
                <Focused>{focused.title}</Focused>
              )}
            </TitleRow>

            <LinksWrapper>
              <ImageLinkList
                items={data.archived.projects}
                uriPrefix="de/projects"
                onMouseEnter={setFocusedUri}
                onMouseOut={() => setFocusedUri(null)}
              />
            </LinksWrapper>
          </>
        )}
      </div>
    </FlexRowCenteredWrap>
  )
}

ProjectsPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  fragment ProjectDetails on ProjectsJson {
    title
    navTitle
    members
    image
    uri
    type
  }

  query ProjectsPageQuery {
    solo: allProjectsJson(
      sort: { fields: title }
      filter: { type: { eq: "solo" } }
    ) {
      projects: nodes {
        ...ProjectDetails
      }
    }
    other: allProjectsJson(
      sort: { fields: title }
      filter: { type: { eq: "other" } }
    ) {
      projects: nodes {
        ...ProjectDetails
      }
    }
    archived: allProjectsJson(
      sort: { fields: title }
      filter: { type: { eq: "archived" } }
    ) {
      projects: nodes {
        ...ProjectDetails
      }
    }
  }
`

export default ProjectsPage
